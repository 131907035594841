<template>
  <v-container fluid align-center class="pt-15">
    <v-row class="mt-5 mx-5" justify="center">
      <v-col cols="6">
        <v-card v-if="detail" v-show="!dialogs.questions" class="pt-3 pb-3" elevation="5" height="655" max-width="1000">
          <v-card-title class="justify-center title ">
            {{ detail.nombre }}
          </v-card-title>
          <v-card-title class="justify-center subtitle">
            {{  detail.descripcion }}
          </v-card-title>
          <v-divider class="ma-5"></v-divider>
          <v-card-text class="text-center">
            <v-row>
              <v-col cols="12">
                <div class="subtitle mb-1">Completado</div>
                <v-progress-circular color="#7000e0" rotate="-90" size="119" width="15" :value="complete">{{ complete + '%' }}</v-progress-circular>
              </v-col>
              <v-col cols="12">
                <v-alert class="ma-2" border="left" elevation="2" dense colored-border type="warning">
                  El formulario podrá ser contestado una sola vez por empresa. Si desea editar alguna respuesta desspués de completar el proceso, favor de contactar al administrador.
                </v-alert>
              </v-col>
            </v-row>
            <v-divider class="my-15"></v-divider>
            <v-row justify="center">
              <v-col cols="12" lg="4">
                <v-btn v-if="true" large rounded color="success" class="text-normal white--text" :disabled="loading || detail.completado" @click="answer()">
                  Responder formulario <v-icon right dark>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" lg="4">
                <v-btn large rounded color="primary" class="text-normal white--text ml-2" :disabled="loading" @click="downloadAnswers()">
                  Descargar respuestas <v-icon right dark>mdi-download</v-icon>
                </v-btn>
              </v-col>
              <v-col v-if="detail.resultados" cols="12" lg="4">
                <v-btn large rounded color="primary" class="text-normal white--text ml-2" :disabled="loading">
                  Imprimir reporte <v-icon right dark>mdi-file</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-dialog v-if="detail && form" v-model="dialogs.questions" persistent max-width="600px">
          <v-card>
            <v-card-title class="justify-center font-weight-bold">
              {{ detail.nombre }}
              <v-spacer></v-spacer>
              <v-btn color="blue-grey" class="text-normal" text :disabled="loading" @click="close()">
                <v-icon left>mdi-logout</v-icon>
                Salir
              </v-btn>
            </v-card-title>
            <v-progress-linear class="my-5" color="blue-grey" :value="progress"></v-progress-linear>
            <v-card-title class="text-center title">
              {{ form.titulo }}
            </v-card-title>
            <v-card-subtitle class="text-center mt-4 subtitle">
              {{ form.subtitulo }}
            </v-card-subtitle>
            <v-card-text class="mt-3">
              <v-container>
                <v-form ref="formQuestions" lazy-va2lidation>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-if="form.tipo === 'texto_simple'" v-model="form.respuesta" label="" :rules="(form.requerido) ? rules.texto_simple.concat(rules._required) : rules.texto_simple" :disabled="loading" outlined required></v-text-field>
                      <v-text-field v-else-if="form.tipo === 'numerico'" v-model="form.respuesta" label="" :rules="(form.requerido) ? rules.numerico.concat(rules._required) : rules.numerico" :disabled="loading" outlined required></v-text-field>
                      <v-autocomplete v-else-if="form.tipo === 'seleccion_simple'" v-model="form.respuesta" label="" :items="form.opciones" :rules="(form.requerido) ? rules.seleccion_simple.concat(rules._required) : rules.seleccion_simple" :disabled="loading" outlined persistent-hint required></v-autocomplete>
                      <v-autocomplete v-else-if="form.tipo === 'seleccion_multiple'" v-model="form.respuesta" label="" :items="form.opciones" :rules="(form.requerido) ? rules.seleccion_multiple.concat(rules._required) : rules.seleccion_multiple" :disabled="loading" multiple outlined persistent-hint required></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-btn block color="blue-grey" class="text-normal" outlined :disabled="!index || loading" @click="back()">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Regresar
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn v-if="(index + 1) < detail.preguntas.length" block color="success" class="text-normal" :loading="loading" @click="next()">
                    Siguiente
                    <v-icon right>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn v-else="(index + 1) == detail.preguntas.length" block color="success" class="text-normal" :loading="loading" @click="save()">
                    Finalizar
                    <v-icon right>mdi-check</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogs.close" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">Aviso</v-card-title>
            <v-card-text>¿Desea guardar los cambios en el formulario antes de salir?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text small :disabled="loading" color="blue-grey" class="text-normal" @click="close()">
                Cancelar
              </v-btn>
              <v-btn text small :disabled="loading" color="error" class="text-normal" @click="cancel()">
                Descartar
              </v-btn>
              <v-btn text small :loading="loading" color="success" class="text-normal" @click="save()">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col v-if="detail && detail.chart" cols="6">
        <v-card class="pt-3 pb-3" elevation="5" height="655" max-width="1000">
          <v-card-title class="justify-center title">Resultados</v-card-title>
          <v-card-text class="mt-10">
            <Radar :chartData="chart.data" :options="chart.options"/>
          </v-card-text>
          <v-divider class="ma-5"></v-divider>
          <v-card-text class="text-center">
            <v-row>
              <v-col cols="6">
                <div class="title-2">Calificación</div>
              </v-col>
              <v-col cols="6">
                <div class="title-2" style="color:#5271ff; font-weight: bold;">{{ detail.resultados.calificacion }}%</div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay absolute :value="loading" opacity="0.8">
      <div v-if="loading" class="text-center">
        <v-progress-circular indeterminate size="60" color="primary" class="mb-5"></v-progress-circular>
        <p>Cargando...</p>
      </div>
    </v-overlay>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import services from '@/utils/services'
import writeXlsxFile from 'write-excel-file'
import Radar from '../components/Charts/Radar.js'
import _ from 'lodash'

export default {
  name: 'Form',
  components: { Radar },
  data: () => ({
    loading: false,
    loading2: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    detail: null,
    chart: {
      data: null,
      options: null
    },
    index: 0,
    form: null,
    rules: {
      _required: [
        v => (!_.isNil(v) && !_.isEmpty(v)) || 'El campo es requerido'
      ],
      texto_simple: [
        v => _.isNil(v) || (!_.isNil(v) && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      numerico: [
        v => v === '' || _.isNil(v) || (/^\d{1,10}(\.\d{1,5})?$/.test(v) && parseFloat(v) >= 0 && parseFloat(parseFloat(v).toFixed(5)) <= 9999999999.99999) || 'El campo es inválido'
      ],
      seleccion_simple: [],
      seleccion_multiple: []
    },
    dialogs: {
      questions: false,
      close: false
    }
  }),
  mounted() {
    this.getDetail()
  },
  computed: {
    company() {
      return this.$store.state.general.company
    },
    report() {
      return this.$store.state.general.report
    },
    progress() {
      return (this.detail) ? parseInt((this.index + 1) / this.detail.preguntas.length * 100) : 0
    },
    complete() {
      let count = 0
      if (this.detail && this.detail.preguntas.length) {
        for (let i = 0; i < this.detail.preguntas.length; i++) {
          if (_.has(this.detail.valores, this.detail.preguntas[i].id)) {
            count += 1
          }
        }
        return parseInt(count / this.detail.preguntas.length * 100)
      }
      return 0
    }
  },
  watch: {
    company: function (val) {
      this.loading = true
      this.clear()
      this.getDetail()
    },
    report: function (val) {
      if (val) {
        this.setReport({ val: false })
      }
    },
    '$route.params.id': function () {
      this.clear()
      this.getDetail()
    },
  },
  methods: {
    ...mapActions('general', [
      'setReport'
    ]),
    clear() {
      this.index = 0
      this.form = null
      this.detail = null
    },
    getDetail() {
      if (this.$store.state.general.company) {
        this.loading = true
        let params = {
          id: this.$route.params.id,
          empresa: this.$store.state.general.company
        }
        this.axios.get(services.routes.form + '/detail', { params })
          .then(response => {
            this.detail = response.data.data
            setTimeout(() => {
              this.chart.data = this.detail.chart.data
              this.chart.options = this.detail.chart.options
            }, 100);
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    cancel() {
      this.index = 0
      this.form = null
      this.dialogs.close = false
      this.dialogs.questions = false
    },
    answer() {
      const preguntas = _.get(this.detail, 'preguntas', []);
      if (preguntas.length) {
        this.loading = true
        setTimeout(() => {
          this.index = 0
          this.form = JSON.parse(JSON.stringify(preguntas[this.index]))
          this.form.respuesta = ''
          if (_.has(this.detail.valores, this.form.id)) {
            this.form.respuesta = this.detail.valores[this.form.id]
          }
          this.dialogs.questions = true
          this.loading = false
        }, 300);
      }
      else {
        this.error = true
        this.msgError = 'El formulario no cuenta con preguntas disponibles'
      }
    },
    next() {
      if (this.$refs.formQuestions.validate()) {
        if ((this.index + 1) < this.detail.preguntas.length) {
          this.index += 1
          this.detail.valores[this.form.id] = this.form.respuesta
          this.$refs.formQuestions.reset()
          this.form = JSON.parse(JSON.stringify(this.detail.preguntas[this.index]))
          this.form.respuesta = ''
          if (_.has(this.detail.valores, this.form.id)) {
            this.form.respuesta = this.detail.valores[this.form.id]
          }
        }
      }
    },
    back() {
      if ((this.index - 1) >= 0 && (this.index - 1) < this.detail.preguntas.length) {
        this.index -= 1
        this.$refs.formQuestions.reset()
        this.form = JSON.parse(JSON.stringify(this.detail.preguntas[this.index]))
        this.form.respuesta = ''
        if (_.has(this.detail.valores, this.form.id)) {
          this.form.respuesta = this.detail.valores[this.form.id]
        }
      }
    },
    save() {
      if (this.$refs.formQuestions.validate()) {
        this.loading = true
        setTimeout(() => {
          this.detail.valores[this.form.id] = this.form.respuesta
          let data = {
            id: this.$route.params.id,
            empresa: this.$store.state.general.company,
            valores: this.detail.valores
          }
          this.axios.put(services.routes.form + '/save', { data })
            .then(response => {
              this.cancel()
              this.getDetail()
              this.success = true
              this.msgSuccess = 'La información fue guardada exitosamente'
            })
            .catch(error => {
              this.error = true
              this.msgError = error.response.data.message || 'Servicio no disponible'
            })
            .finally(() => {
              this.loading = false
            })
        }, 300);
      }
    },
    close() {
      if (!this.dialogs.close) {
        this.dialogs.close = true
      }
      else {
        this.dialogs.close = false
      }
    },
    downloadAnswers() {
      this.loading = true
      setTimeout(() => {
        let params = {
          id: this.$route.params.id,
          empresa: this.$store.state.general.company,
        }
        this.axios.get(services.routes.form + '/answer', { params })
          .then(response => {
            if (response.data.data.length) {
              let data = []
              let headers = []
              for (let key in response.data.data[0]) {
                headers.push({
                  value: key,
                  fontWeight: 'bold'
                })
              }
              data.push(headers)
              for (let i = 0; i < response.data.data.length; i++) {
                let rows = []
                for (let key in response.data.data[0]) {
                  rows.push({
                    type: String,
                    value: response.data.data[i][key]
                  })
                }
                data.push(rows)
              }
              writeXlsxFile(data, { fileName: this.detail.nombre + '.xlsx' })
              this.loading = false
            }
            else {
              this.error = true
              this.msgError = 'No hay información disponible'
            }
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }, 300);
    }
  }
}
</script>

<style scoped>
  .text-normal {
    text-transform: none !important;
  }
  .title {
    word-wrap: break-word;   
    overflow-wrap: break-word;
    word-break: break-word;
    font-size: 30px !important;
    font-family: 'Quicksand', sans-serif !important;
    color: #5271ff;
  }
  .subtitle {
    word-wrap: break-word;   
    overflow-wrap: break-word;
    word-break: break-word;
    font-size: 15px !important;
    font-family: 'Quicksand', sans-serif !important;
    color: grey;
  }
  .title-2 {
    word-wrap: break-word;   
    overflow-wrap: break-word;
    word-break: break-word;
    font-size: 20px !important;
    font-family: 'Quicksand', sans-serif !important;
  }
</style>
