const host = process.env.VUE_APP_API_SERVER

const routes = {
  profile: host + '/api/client/profile',
  items: host + '/api/client/profile/item',
  updateProfile: host + '/api/client/profile',
  professionalSector: host + '/pub/professionalSector',
  emissionSource: host + '/api/client/hdc/emissionSource',
  chart: host + '/api/client/hdc/chart',
  pathwayNetZero: host + '/api/client/hdc/chart/pathwayNetZero',
  record: host + '/api/client/hdc/record',
  notifications: host + '/api/client/notification',
  updateNotifications: host + '/api/client/notification/read',
  objective: host + '/api/client/company/objective',
  report: host + '/api/client/dashboard/report',
  reductionMeasureItems: host + '/api/client/dashboard/reductionMeasureItems',
  kpis: host + '/api/client/dashboard/kpi',
  hdc: host + '/api/client/dashboard/hdc',
  emissionByCategory: host + '/api/client/dashboard/emissionByCategory',
  emissionOverTime: host + '/api/client/dashboard/emissionOverTime',
  emissionLocation: host + '/api/client/dashboard/emissionLocation',
  reductionMeasures: host + '/api/client/dashboard/reductionMeasure',
  ec: {
    dashboard: host + '/api/client/ec/dashboard',
    circularityMeasure: host + '/api/client/ec/circularityMeasure',
    report: host + '/api/client/ec/report',
  },
  garden: {
    report: host + '/api/client/garden/report',
  },
  supplier: host + '/api/client/supplier',
  form: host + '/api/client/form'
}

export default {
  routes
}
